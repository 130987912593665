<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Question -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('QUESTION')"
                      label-for="id-question"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('QUESTION')"
                        rules="required"
                      >
                        <quill-editor
                          id="id-question"
                          v-model="question.statement"
                          maxlength="800"
                          :options="editorOption"
                          class="border-bottom-0"
                        />
                        <div
                          id="quill-toolbar"
                          class="d-flex justify-content-end border-top-0"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold" />
                          <button class="ql-italic" />
                          <button class="ql-underline" />
                          <button class="ql-align" />
                          <button class="ql-link" />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Referance ID -->
                  <b-col
                    v-show="question.question_variety==='Private'"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="question.reference_id"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    v-show="question.question_variety==='Private'"
                    lg="6"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Category"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('CATEGORY')"
                        label-for="id-category"
                      >
                        <v-select
                          id="id-category"
                          v-model="question.categories"
                          :placeholder="$t('SELECT_CATEGORY')"
                          multiple
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="categoryOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group></validation-provider>
                  </b-col>
                  <!-- Language -->
                  <b-col
                    v-show="question.question_variety==='Private'"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >
                        <v-select
                          id="id-language"
                          v-model="language"
                          :options="languageOptions"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Question type -->
                  <b-col
                    v-show="question.question_variety==='Private'"
                    cols="3"
                  >
                    <b-form-group
                      :label="$t('QUESTION_TYPE')"
                      label-for="id-questionType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('QUESTION_TYPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-questionType"
                          v-model="question.question_type"
                          :placeholder="$t('SELECT_QUESTION_TYPE')"
                          :clearable="false"
                          class="flex-grow-1"
                          label="name"
                          :options="questionType"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Emoji -->
                  <b-col
                    v-if="question.question_type === questionType[0]"
                    cols="3"
                  >
                    <b-form-group
                      :label="$t('EMOJI')"
                      label-for="id-Emoji"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('EMOJI')"
                        rules="required"
                      >
                        <v-select
                          v-model="question.emoji"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="emojies"
                          label="title"
                          :clearable="false"
                        >
                          <template #option="{ title, icon }">
                            <feather-icon
                              :icon="icon"
                              size="16"
                              class="align-middle mr-50"
                            />
                            <span> {{ title }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Minimum Value -->
                  <b-col
                    v-if="question.question_type === questionType[0]"
                    cols="3"
                  >
                    <b-form-group
                      :label="$t('MINIMUM_VALUE')"
                      label-for="id-minimum"
                    >
                      <b-form-spinbutton
                        id="id-minimum"
                        v-model="question.minimum_value"
                        min="0"
                        max="10"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Maximum Value -->
                  <b-col
                    v-if="question.question_type === questionType[0]"
                    cols="3"
                  >
                    <b-form-group
                      :label="$t('MAXIMUM_VALUE')"
                      label-for="id-maximum"
                    >
                      <b-form-spinbutton
                        id="id-maximum"
                        v-model="question.maximum_value"
                        min="0"
                        max="10"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Element -->
                  <b-col
                    v-if="question.question_type === questionType[2]"
                    lg="3"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('ELEMENT')"
                      label-for="id-element"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('ELEMENT')"
                        rules="required"
                      >
                        <v-select
                          id="id-element"
                          v-model="question.element"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="elementType"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Submit and reset -->
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{
                        question.questionID
                          ? $t("BUTTON.UPDATE")
                          : $t("BUTTON.ADD")
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t("BUTTON.RESET") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="question.question_type === questionType[2]">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <div>
              <b-form
                ref="form"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in question.question_answers"
                  :id="item.ID.toString()"
                  :key="item.ID"
                  ref="row"
                >
                  <!-- Answer -->
                  <b-col
                    lg="3"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('ANSWER')"
                      label-for="answer"
                    >
                      <b-form-input
                        :id="item.ID.toString()"
                        v-model="question.question_answers[index].answer"
                        type="text"
                        maxlength="100"
                        :placeholder="$t('ANSWER')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Right -->
                  <b-col
                    lg="2"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('CORRECT')"
                      label-for="correct"
                    >
                      <b-form-checkbox
                        :id="'chk_answer' + item.ID.toString()"
                        :key="index"
                        v-model="question.question_answers[index].is_correct"
                        value="1"
                        unchecked-value="0"
                        class="mt-0 mt-md-2"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="4"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-2"
                      @click="
                        removeItem(index, question.question_answers[index])
                      "
                    >
                      <feather-icon
                        icon="Trash2Icon"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t("BUTTON.ADD_NEW") }}</span>
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormSpinbutton,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormSpinbutton,
    BFormCheckbox,
    quillEditor,
  },
  data() {
    return {
      question: {
        questionID: 0,
        mediaID: 0,
        statement: '',
        reference_id: null,
        isCorrect: 0,
        element: '',
        language: '',
        question_variety: 'Private',
        question_type: constants.QUESTION_TYPE[0],
        minimum_value: 1,
        maximum_value: 5,
        emoji: '',
        categories: [],
        question_answers: [],
      },
      categoryOptions: [],
      questionType: constants.QUESTION_TYPE,
      emojies: constants.EMOJI,
      elementType: constants.ELEMENT_TYPE,
      language: '',
      languageOptions: constants.LANGUAGES,
      required,
      nextTodoId: 2,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your Question',
    }

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      editorOption,
      successMessage,
      showErrorMessage,
    }
  },
  computed: {
    ...mapGetters('template', ['getAllCategories']),
  },
  async created() {
    await this.fetchCategory()
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.question.questionID = parseInt(router.currentRoute.params.id)
      if (this.question.questionID !== 0) {
        this.fetchQuestionByID(this.question.questionID).then(response => {
          if (response) {
            this.question = response.data
            if (this.question) {
              this.language = this.languageOptions.find(
                i => i.id === this.question.language,
              )
            }
          }
        })
      }
    } else {
      this.question.question_answers.push({
        ID: 1,
        answer: '',
        is_correct: 0,
      })
    }
  },
  methods: {
    ...mapActions('survey', [
      'createQuestion',
      'deleteQuestionAnswer',
      'updateQuestion',
      'fetchQuestionByID',
    ]),
    ...mapActions('template', ['fetchAllCategories']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.question.language = this.language.id
          if (
            this.question.emoji !== null
            && this.question.emoji !== undefined
          ) {
            this.question.emoji = this.question.emoji.title !== null
              ? this.question.emoji.title
              : ''
          }
          if (this.question.questionID === 0) {
            this.createQuestion(this.question)
              .then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.QUESTION_CREATED'))
                  this.$router.push({ name: 'question-list' })
                }
              })
              .catch(() => {
                this.showErrorMessage()
              })
          } else {
            this.updateQuestion(this.question)
              .then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.QUESTION_UPDATED'))
                  this.$router.push({ name: 'question-list' })
                }
              })
              .catch(() => {
                this.showErrorMessage()
              })
          }
        }
      })
    },
    repeateAgain() {
      this.question.question_answers.push({
        answer: '',
        is_correct: 0,
        ID: (this.nextTodoId += this.nextTodoId),
      })
    },
    removeItem(index, data) {
      this.question.question_answers.splice(index, 1)
      if (data.created_timestamp) {
        this.deleteQuestionAnswer(data)
      }
    },
    async fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
